import { CreateActionParams } from '../actions';

export type GetUpcoming = () => void;

export function createGetUpcomingAction({
  context,
  getControllerState,
}: CreateActionParams): GetUpcoming {
  return async () => {
    const [, setState] = getControllerState();
    const { api } = context;

    const upcomingData = await api.getUpcoming();

    setState({ upcomingData });
  };
}
