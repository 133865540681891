import { Bookings } from '../Bookings';
import { EnvironmentModes } from '../../api/implementations/environmentModes';

export type ApplicationState = {
  status: ApplicationStatus;
  environmentModes: EnvironmentModes;
  upcomingData?: Bookings;
};

export const enum ApplicationStatus {
  IDLE = 'idle',
  INITIALIZING = 'initializing',
}
