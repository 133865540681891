import { ActionFactoryParams } from '../core/ControlledComponent/types';
import { ApplicationState } from '../types';
import { ActionsContext } from '../core/actionsContext/contextFactory';
import { createGetUpcomingAction, GetUpcoming } from './getUpcoming';

export type CreateActionParams = ActionFactoryParams<
  ApplicationState,
  ActionsContext
>;

export type ControllerActions = {
  getUpcoming: GetUpcoming;
};

export const createActions = (
  actionFactoryParams: ActionFactoryParams<ApplicationState, ActionsContext>,
): ControllerActions => {
  const actions = {
    getUpcoming: createGetUpcomingAction({ ...actionFactoryParams }),
  };

  return actions;
};
