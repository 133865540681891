import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export type ApiContext = {
  flowAPI: ControllerFlowAPI;
  authorization: string;
};

export enum API_DOMAINS {
  MEMBER_AREA = '/_api/bookings-member-area',
}
